<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('themes.new')">
        <actions
          slot="actions"
          crud-links="themes"
          :actions="actions"
        />
        <data-form
          :theme="theme"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'themes-new',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      headers: { 'Content-Type': 'multipart/form-data' },
      theme: {
        active: false,
      },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'themes/'
    },
    async submit (theme) {
      this.loading = true

      let response = false
      try {
        response = await this.$http.post(this.routeBuilder(), theme, { headers: this.headers })
      } catch (e) {
        // console.log('Error while creating theme', e)
        this.loading = false
        return
      }

      if (theme.get('active') === 'true') {
        let t = false
        try {
          t = await this.$http.get('themes/active')
        } catch (e) {
          // console.log('Error updating theme', e)
        }
        this.$store.commit('updateTheme', t.data)
        this.$store.commit('patchTheme')
      }

      this.$router.push({ name: 'themesEdit', params: { id: response.data.data.id } })
    },
  },
}
</script>
